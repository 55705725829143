import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  equipmentConfigurations,
  equipmentConfigurationsForm,
  instalations,
  instalationsForm,
  projects,
  projectsForm,
  imputHours,
  imputHoursForm,
  singUps,
  singUpsForm,
  equipments,
  equipmentsForm,
  navUsers,
  navCustomers,
  incidences,
  incidencesForm,
  satisfactions,
  satisfactionsForm,
  alerts,
  alertsForm,
  shopCarts,
  shopCartsForm,
  articles,
  securitySheets,
  securitySheetsForm,
  invoices,
  deliveryNotes,
  downloadDocuments,
  signForm,
  repartidors,
  repartiments,
  suggestions,
  suggestionsForm,
  establishmentInfo,
  establishmentInfoForm,
  tags,
  tagsForm,
  expenses,
  expensesForm,
  OPCUA,
  OPCUAForm,
  timeRequests,
  timeRequestsForm,
  holidayRequests,
  holidayRequestsForm,
  workShifts,
  workShiftsForm,
  dosificationProducts,
  dosificationProductsForm,
  dosificationConfigs,
  dosificationConfigsForm,
  leaves,
  leavesForm,
  reasons,
  reasonsForm,
  workOrders,
  workOrdersForm,
  products, rolesForm, roles, equipmentTypesForm,
  components,
  componentsForm,
  componentTypes,
  componentTypesForm,
  maintenances,
  maintenancesForm,
  equipmentTypes,
  currentTheme
} from "./Reducers";

export default combineReducers({
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  navCustomers,
  equipmentConfigurations,
  equipmentConfigurationsForm,
  instalations,
  instalationsForm,
  projects,
  projectsForm,
  imputHours,
  imputHoursForm,
  singUps,
  singUpsForm,
  equipments,
  equipmentsForm,
  navUsers,
  incidences,
  incidencesForm,
  satisfactions,
  satisfactionsForm,
  alerts,
  alertsForm,
  shopCarts,
  shopCartsForm,
  articles,
  securitySheets,
  securitySheetsForm,
  invoices,
  deliveryNotes,
  downloadDocuments,
  signForm,
  repartidors,
  repartiments,
  suggestions,
  suggestionsForm,
  establishmentInfo,
  establishmentInfoForm,
  tags,
  tagsForm,
  expenses,
  expensesForm,
  OPCUA,
  OPCUAForm,
  timeRequests,
  timeRequestsForm,
  holidayRequests,
  holidayRequestsForm,
  workShifts,
  workShiftsForm,
  dosificationProducts,
  dosificationProductsForm,
  dosificationConfigs,
  dosificationConfigsForm,
  leaves,
  leavesForm,
  reasons,
  reasonsForm,
  equipmentTypesForm,
  products,
  components,
  componentsForm,
  componentTypes,
  componentTypesForm,
  maintenances,
  maintenancesForm,
  workOrders,
  workOrdersForm,
  equipmentTypes,
  rolesForm,
  roles,
  currentTheme
});
